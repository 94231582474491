<template>
  <terms :mode="isLoggedIn ? 'detail' : 'full'" />
</template>

<script>
import { mapGetters } from "vuex";
import Terms from "@/views/components/common/Terms";
export default {
  components: {
    Terms
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn'])
  },
};
</script>